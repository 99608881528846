import { createContext, useContext, useEffect, useState } from "react";

const InactivityContext = createContext();
const channel = new BroadcastChannel("inactivity_timer");

export const InactivityProvider = ({ children }) => {
  const [pauseTimer, setPauseTimer] = useState(false);

  useEffect(() => {
    channel.onmessage = (message) => {
      if (message.data.type === "PAUSE_TIMER_UPDATE") {
        console.log(
          "Received PAUSE_TIMER_UPDATE from another tab:",
          message.data.payload
        );
        setPauseTimer(message.data.payload);
      }
    };
  }, []);

  useEffect(() => {
    //when window loads, check to see if we should be paused
    const storedPauseUntil = parseInt(
      localStorage.getItem("dontBotherMeUntil")
    );
    if (storedPauseUntil && storedPauseUntil > Date.now()) {
      setPauseTimer(true);
    }
  }, []);

  // **Rebroadcast Pause Timer Every 5 Minutes**
  useEffect(() => {
    let interval;
    if (pauseTimer) {
      interval = setInterval(() => {
        console.log("Rebroadcasting PAUSE_TIMER_UPDATE:", pauseTimer);
        channel.postMessage({
          type: "PAUSE_TIMER_UPDATE",
          payload: pauseTimer,
        });
      }, 30000); // Every 30 seconds
    }

    return () => clearInterval(interval);
  }, [pauseTimer]); // Only runs when `pauseTimer` changes

  // Broadcast pauseTimer state change
  const updatePauseTimer = (newState) => {
    console.log("Broadcasting PAUSE_TIMER_UPDATE:", newState);
    setPauseTimer(newState);
    channel.postMessage({ type: "PAUSE_TIMER_UPDATE", payload: newState });

    if (newState) {
      const pauseUntil = Date.now() + 60 * 60 * 1000; //60 minutes
      localStorage.setItem("dontBotherMeUntil", pauseUntil);

      setTimeout(() => {
        console.log("Auto-resuming timer after 60 minutes...");
        setPauseTimer(false);
        localStorage.removeItem("dontBotherMeUntil");
        channel.postMessage({ type: "PAUSE_TIMER_UPDATE", payload: false });
      }, 60 * 60 * 1000);
    }
  };

  return (
    <InactivityContext.Provider
      value={{ pauseTimer, setPauseTimer: updatePauseTimer }}
    >
      {children}
    </InactivityContext.Provider>
  );
};

export const useInactivity = () => useContext(InactivityContext);
