import { useEffect, useState } from "react";

const useInactivityTimer = (
  startTime,
  showTime,
  showInactivityDialog,
  setShowInactivityDialog,
  isAuthenticated,
  pauseTimer,
  handleLogout
) => {
  const [timer, setTimer] = useState(startTime);
  const [shouldLogout, setShouldLogout] = useState(false);

  const channel = new BroadcastChannel("inactivity_timer");

  console.log(timer);

  const resetTimeout = (fromBroadcast = false) => {
    setTimer(startTime);

    if (typeof setShowInactivityDialog === "function") {
      //this stops bad errors from happening
      setShowInactivityDialog(false);
    } else {
      console.error("setShowInactivityDialog is not a function");
    }

    // If this is not from another tab, broadcast the reset
    if (!fromBroadcast) {
      channel.postMessage({ type: "RESET_TIMEOUT" });
    }
  };

  useEffect(() => {
    const handleEvent = () => {
      if (isAuthenticated && !showInactivityDialog && !pauseTimer) {
        resetTimeout();

        channel.postMessage({ type: "RESET_TIMEOUT" });
      }
    };

    const events = ["load", "mousedown", "click", "scroll", "keypress"];
    if (isAuthenticated && !pauseTimer) {
      // Need to reattach after pause
      for (let event of events) {
        window.addEventListener(event, handleEvent);
      }
    } else {
      // Remove event listeners when paused or logged out
      for (let event of events) {
        window.removeEventListener(event, handleEvent);
      }
    }

    return () => {
      for (let event of events) {
        window.removeEventListener(event, handleEvent);
      }
    };
  }, [isAuthenticated, pauseTimer, showInactivityDialog]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (timer > 0 && !pauseTimer) {
      //adding a pause here for file hasher
      const timerId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [timer, pauseTimer]);

  useEffect(() => {
    if (timer <= showTime && isAuthenticated && timer !== startTime) {
      setShowInactivityDialog(true);
    }
    if (timer > showTime) {
      setShowInactivityDialog(false);
    }
  }, [timer, isAuthenticated, setShowInactivityDialog]); // eslint-disable-line react-hooks/exhaustive-deps

  //Look for other messages from other tabs
  useEffect(() => {
    channel.onmessage = (message) => {
      if (message.data.type === "RESET_TIMEOUT") {
        console.log("resetting");
        resetTimeout(true); // Avoid rebroadcasting and avoid infinite loop
      } else if (message.data.type === "LOGOUT") {
        console.log("Received LOGOUT event.");
        setShouldLogout(true); // UPDATE STATE INSTEAD OF DIRECTLY CALLING FUNCTION
      }
    };

    return () => {
      channel.close();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shouldLogout) {
      console.log("Logging out due to broadcasted event.");
      setShouldLogout(false); // Reset after logging out
      if (typeof handleLogout === "function") {
        handleLogout();
      } else {
        console.error("handleLogout is not a function");
      }

      // handleLogout();
    }
  }, [shouldLogout]); // eslint-disable-line react-hooks/exhaustive-deps

  return [timer, resetTimeout];
};

export default useInactivityTimer;
